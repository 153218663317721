const initialStateVal = {
  templateDetails: [],
  rowData: {},
  fileUpload: [],
  tcdropdownValues: {
    letterTypes: {},
    region: {},
  },
  tcdropdownLists: {
    region: [],
  },
};
const TemplateConfigReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "updateTemplateData":
      return {
        ...state,
        rowData: action.payload.rowData,
        templateDetails: action.payload.templateDetails,
        reqBody: action.payload.reqBody,
        fileUpload: action.payload.fileUpload,
      };
    case "tcDropDownUpdate":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    default:
      return state;
  }
};
export default TemplateConfigReducer;
