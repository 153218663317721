import JWTInterceptor from "../../../../JWT/JWTInterceptor";
const LoginService = {
  verifyUser: async () => {
    return JWTInterceptor.get(`${process.env.REACT_APP_API_FH_BASE_URL}auth`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  userDetails: async () => {
    return JWTInterceptor.get(
      `${
        process.env.REACT_APP_API_FH_BASE_URL
      }users?with[]=AssignedRoles.AssignedAreas&with[]=AssignedRoles.Role&with[]=Locale&whereEmail=${localStorage.getItem(
        "userName"
      )}`
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  countyListDropDown: async () => {
    return JWTInterceptor.get(
      `${process.env.REACT_APP_API_FH_BASE_URL}areas?limit=1000&whereTypeName=Country`
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
};
export default LoginService;
