import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BlockUI } from "primereact/blockui";
import { useDispatch, useSelector } from "react-redux";
import SffLanguage from "./Components/Common/Languages/SffLanguage";
import LoginService from "./Components/Modules/Login/Utills/LoginService";
import ErrorBoundary from "./Components/Common/ErrorBoundary/ErrorBoundary";
import { RouteAuthorize } from "./Components/Common/RouteAuthorize/RouteAuthorize";
import { Dialog } from "primereact/dialog";
import sessionImage from "./Images/session-timeout.svg";
import networkIssueImage from "./Images/Network-error.svg";
import SessionPopUp from "./JWT/SessionPopUp";
const Login = lazy(() => import("./Components/Modules/Login/Page/Login"));
const Navbar = lazy(() => import("./Components/Common/NavBar/Navbar"));
const Home = lazy(() => import("./Components/Modules/Home/Home"));
const ChildIlReview = lazy(() =>
  import("./Components/Modules/ChildILReview/Page/ChildIlReview")
);
const PhotPendingReview = lazy(() =>
  import("./Components/Modules/PhotoPendingReview/Page/PhotPendingReview")
);
const PendingPhotoMainPage = lazy(() =>
  import("./Components/Modules/PhotoPendingReview/Page/PendingPhotoMainPage")
);
const ChildVideoReview = lazy(() =>
  import("./Components/Modules/ChildIntroVideoReview/Page/ChildVideoReview")
);
const ChildIIPendingReview = lazy(() =>
  import("./Components/Modules/ChildILReview/Page/ChildIIPendingReview")
);
const ChildIIPendingReviewMainPage = lazy(() =>
  import(
    "./Components/Modules/ChildIntroVideoReview/Page/ChildVideoReviewMainPage"
  )
);
const ChildReplyReviewPendings = lazy(() =>
  import(
    "./Components/Modules/ChildReplyLetterReview/Page/ChildReplyReviewPendings"
  )
);
const ChildReplyLetterReview = lazy(() =>
  import(
    "./Components/Modules/ChildReplyLetterReview/Page/ChildReplyLetterReview"
  )
);
const ChristmasCardReviewPendings = lazy(() =>
  import(
    "./Components/Modules/ChristmasCardReview/Page/ChristmasCardReviewPendings"
  )
);
const ChristmasCardReview = lazy(() =>
  import("./Components/Modules/ChristmasCardReview/Page/ChristmasCardReview")
);
const ChildGreetingVideoReview = lazy(() =>
  import(
    "./Components/Modules/ChildGreetingVideoReview/Page/ChildGreetingVideoMainPage"
  )
);
const ChildGreetingVideoReviewDetails = lazy(() =>
  import(
    "./Components/Modules/ChildGreetingVideoReview/Page/ChildGreetingVideoReview"
  )
);
const ExceptionVideoMessage = lazy(() =>
  import(
    "./Components/Modules/ExceptionVideoMessageReview/Page/ExceptionVideoMessage"
  )
);
const ExceptionVideoMessageReview = lazy(() =>
  import(
    "./Components/Modules/ExceptionVideoMessageReview/Page/ExceptionVideoMessageReview"
  )
);
const TemplateConfiguration = lazy(() =>
  import("./Components/Modules/TemplateConfig/Page/LetterTypeLists")
);

const LetterCreationMode = lazy(() =>
  import("./Components/Modules/LetterCreationMode/Page/LetterCreationMode")
);

const ChildIntroLetterTemplate = lazy(() =>
  import("./Components/Modules/TemplateConfig/Page/ChildIntroLetterTemplate")
);

const ChildPreviewTemplate = lazy(() =>
  import("./Components/Modules/TemplateConfig/Page/PreviewTemplate")
);

const ChildILPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChildILReview/Page/ChildIlPreview")
);
const RlPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChildReplyLetterReview/Page/PreviewRlPdf")
);

const ChildCCPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChristmasCardReview/Page/ChristmasCardPreview")
);
const DownloadLetterTemp = lazy(() =>
  import("./Components/Modules/DownloadLetterTemp/Page/DownloadLetterTemp")
);
const childPhotoUpdate = lazy(() =>
  import("./Components/Modules/ChildPhotoUpdate/Page/ChildPhotoUpdate")
);
const childPhotoUpdateReview = lazy(() =>
  import("./Components/Modules/ChildPhotoUpdate/Page/ChildPhotoUpdateReview")
);
const sponsorLetter = lazy(() =>
  import("./Components/Modules/Sponsor Letter/Page/SponserLetter")
);
const sponsorLetterReview = lazy(() =>
  import("./Components/Modules/Sponsor Letter/Page/SponserLetterReview")
);
const sponsorLetterPreview = lazy(() =>
  import("./Components/Modules/Sponsor Letter/Page/PreviewSponserLetter")
);
const UploadSponsorMessage = lazy(() =>
  import("./Components/Modules/UploadSponsorMessage/Page/UploadSponsorMessage")
);
const UploadSponsorReview = lazy(() =>
  import("./Components/Modules/UploadSponsorMessage/Page/UploadSponsorReview")
);
const ChildRMPendingReview = lazy(() =>
  import(
    "./Components/Modules/ChildReplyMessage/Page/ChildReplyMessaagePending"
  )
);
const ChildReplyMessageReview = lazy(() =>
  import("./Components/Modules/ChildReplyMessage/Page/ChildReplyMessageReview")
);
const RmPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChildReplyMessage/Page/PreviewRMPdf")
);

const menuRole = {
  qaReview: 80,
  admin: 1,
  fo: 77,
  sdo: 82,
  gscUser: 74,
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const loginReduxData = useSelector((state) => state.LoginReducer);
  const action = useDispatch();
  const [loader, setLoader] = useState(false);
  const [invalidUser, setinvalidUser] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const { t, changeLanguage } = SffLanguage();
  const [showMenu, setShowMenu] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const showMenuNavbar = (value) => {
    setShowMenu(value);
  };
  const checkUser = async () => {
    if (location.pathname != "/login") {
      try {
        setLoader(true);
        let response = await LoginService.userDetails();
        if (!response.isAxiosError) {
          if (response.data.success) {
            setRoleId(response.data.data[0].AssignedRoles[0].Role.RoleId);
            localStorage.setItem("you", response.data.data[0].UserId);
            action({
              type: "updateLoginDetails",
              payload: {
                name: "loggedUserDetails",
                value: response.data.data,
              },
            });
            if (response.data.data[0].AssignedRoles[0].Role.RoleId !== 82)
              getCountryDetails();
          }
        } else {
          if (response.response?.status === 401) {
            setinvalidUser(true);
          } else if (response.code == "ERR_NETWORK") {
            setNetworkError(true);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    }
  };
  const getCountryDetails = async () => {
    try {
      setLoader(true);
      let response = await LoginService.countyListDropDown();
      if (!response.isAxiosError) {
        if (response.data.success) {
          action({
            type: "updateLoginDetails",
            payload: {
              name: "countriesDropDown",
              value: response.data.data,
            },
          });
          action({
            type: "updateLoginDetails",
            payload: {
              name: "selectedCounty",
              value: response.data.data[0],
            },
          });
        }
      }
    } catch {
      console.log("error county");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    checkUser();
    changeLanguage(localStorage.getItem("code"));
  }, [localStorage.getItem("userName")]);
  const suspenseFallback = (
    <div className="three-dot-fallback">
      <ThreeDots height="80" width="80" color="#40BBBD" />
    </div>
  );

  function routeElement(Component, roleId, menuRole) {
    return (
      <Suspense fallback={suspenseFallback}>
        <RouteAuthorize userRole={roleId} menuRole={menuRole}>
          <Component />
        </RouteAuthorize>
      </Suspense>
    );
  }

  return (
    <BlockUI
      blocked={loader}
      fullScreen
      template={<ThreeDots height="80" width="80" color="#40BBBD" />}
    >
      <SessionPopUp invalidUser={invalidUser} networkError={networkError} />
      <div className="App">
        {location.pathname != "/login" && (
          <Navbar showMenuNavbar={showMenuNavbar} />
        )}
        <div
          className="main-container"
          style={
            location.pathname != "/login"
              ? showMenu
                ? { marginLeft: "60px" }
                : { marginLeft: "290px" }
              : {}
          }
        >
          <Suspense fallback={suspenseFallback}>
            {/* <Suspense fallback={"Loading"}> */}
            <ErrorBoundary>
              <Routes>
                <Route path="/login" element={<Login />} />
                {roleId !== 0 && (
                  <>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route
                      path="/ilreview"
                      element={<ChildIIPendingReview />}
                    />
                    <Route
                      path="/rmreview"
                      element={routeElement(
                        ChildRMPendingReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/rmreview/operation"
                      element={routeElement(
                        ChildReplyMessageReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/rmreview/preview"
                      element={routeElement(
                        RmPreviewTemplate,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/ilreview/operation"
                      element={<ChildIlReview />}
                    />
                    <Route
                      path="/photoreview/operation"
                      element={routeElement(
                        PhotPendingReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/photoreview"
                      element={routeElement(
                        PendingPhotoMainPage,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/childvideoreview"
                      element={routeElement(
                        ChildIIPendingReviewMainPage,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/childvideoreview/operation"
                      element={routeElement(
                        ChildVideoReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/replyletterreview"
                      element={routeElement(
                        ChildReplyReviewPendings,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/replyletterreview/operation"
                      element={routeElement(
                        ChildReplyLetterReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/greetingvideo"
                      element={routeElement(
                        ChildGreetingVideoReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/greetingvideo/operation"
                      element={routeElement(
                        ChildGreetingVideoReviewDetails,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/exceptionvideo"
                      element={routeElement(
                        ExceptionVideoMessage,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/exceptionvideo/operation"
                      element={routeElement(
                        ExceptionVideoMessageReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/christmascard"
                      element={routeElement(
                        ChristmasCardReviewPendings,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/christmascard/operation"
                      element={routeElement(
                        ChristmasCardReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/sponsorletter"
                      element={routeElement(
                        sponsorLetter,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/sponsorletter/review"
                      element={routeElement(
                        sponsorLetterReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/sponsorletter/preview"
                      element={routeElement(
                        sponsorLetterPreview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/settings/templateconfiguration"
                      element={routeElement(
                        TemplateConfiguration,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/lettercreationmode"
                      element={routeElement(
                        LetterCreationMode,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/settings/templateconfiguration/add"
                      element={routeElement(
                        ChildIntroLetterTemplate,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/settings/templateconfiguration/preview"
                      element={routeElement(
                        ChildPreviewTemplate,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/ilreview/preview"
                      element={routeElement(
                        ChildILPreviewTemplate,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/christmascard/preview"
                      element={routeElement(
                        ChildCCPreviewTemplate,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/replyletterreview/preview"
                      element={routeElement(
                        RlPreviewTemplate,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/settings/downloadletter"
                      element={routeElement(
                        DownloadLetterTemp,
                        roleId,
                        menuRole.fo
                      )}
                    />
                    <Route
                      path="/childphotoupdate"
                      element={routeElement(
                        childPhotoUpdate,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/childphotoupdate/review"
                      element={routeElement(
                        childPhotoUpdateReview,
                        roleId,
                        roleId === 80 ? menuRole.qaReview : menuRole.sdo
                      )}
                    />
                    <Route
                      path="/uploadsponsormessage"
                      element={routeElement(
                        UploadSponsorMessage,
                        roleId,
                        menuRole.gscUser
                      )}
                    />
                    <Route
                      path="/uploadsponsormessage/operation"
                      element={routeElement(
                        UploadSponsorReview,
                        roleId,
                        menuRole.gscUser
                      )}
                    />
                  </>
                )}
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </div>
      </div>
    </BlockUI>
  );
}

export default App;
