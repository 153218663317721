const initialStateVal = {
  rejectMessage: "",
  communityDropDownValue: [],
  dropDownValue: {
    community: [],
    country: [],
  },
  selectedValue: {
    community: [],
    country: [],
  },
  childDetails: {},
};
const ChildPhotoUpdateReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "cupStoreDataFilter":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "cupUpdateKeyWisePhoto":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "cupDetailsUpdater":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "cupOnClear":
      return {
        ...state,
        childDetails: {},
        rejectMessage: "",
      };
    default:
      return state;
  }
};
export default ChildPhotoUpdateReducer;
