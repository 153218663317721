import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./en/translations.json"),
    },
    es: {
      translations: require("./es/translations.json"),
    },
    km: {
      translations: require("./km/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es", "km"];

export default i18n;
