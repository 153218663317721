const initialStateVal = {
  slDropdownOptions: {
    community: [],
    country: [],
    sponsorLetterTypes: [],
  },
  slDropdownValues: {
    community: [],
    country: [],
  },
  slDetails: {},
  slLetters: {},
  slTranslationData: [],
  historyDetails: [],
};
const SponsorLetterReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "sponsorLetterTableData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "slTransalation":
      let currentValue = [...state["slTranslationData"]];
      currentValue[action.payload.propIndex]["translation"] =
        action.payload.propValue;

      return {
        ...state,
        [action.payload.name]: currentValue,
      };
    case "sponsorLetterFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "slOnClear":
      return {
        ...state,
        slTranslationData: [],
        slDetails: {},
        slLetters: {},
      };
    default:
      return state;
  }
};
export default SponsorLetterReducer;
