import { Fragment } from "react";
import { Navigate } from "react-router-dom";

export function RouteAuthorize({ children, userRole, menuRole }) {
  // 80 - QA Reviewer
  // 1 - Super Admin
  // 77 - FO Child Sponsor Contributor
  const canAccess = userRole == menuRole ? true : false;

  if (canAccess) return <Fragment>{children}</Fragment>;

  return <Navigate to="/" />;
}
