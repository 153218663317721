import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Jwt from "./JWTInterceptor";
import { Dialog } from "primereact/dialog";
import sessionImage from "../Images/session-timeout.svg";
import networkIssueImage from "../Images/Network-error.svg";
import SffLanguage from "../Components/Common/Languages/SffLanguage";
function SessionPopUp(props) {
  const navigate = useNavigate();
  const { t } = SffLanguage();
  const interceptorId = useRef(null);
  const [sessionShow, setSessionShow] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  useEffect(() => {
    interceptorId.current = Jwt.interceptors.response.use(
      undefined,
      (error) => {
        try {
          switch (error.response.status) {
            case 401:
              if (
                error.response.data.message !==
                "The provided username-password combination was incorrect."
              ) {
                setSessionShow(true);
              }
              break;
            default:
              return Promise.reject(error);
          }
        } catch (e) {
          setNetworkError(true);
        } finally {
        }
      }
    );
  }, [navigate]);
  return (
    <div className="sessiopn">
      <Dialog
        visible={sessionShow || props.invalidUser}
        style={{ width: "40vw" }}
        breakpoints={{ "1000px": "70vw", "500px": "95vw" }}
        draggable={false}
        modal
        onHide={() => (window.location = "/login")}
      >
        <img src={sessionImage} className="dialoge-image" alt="Loading" />
        <div className="text-center pop-msg-lable">
          {t("WBA_GEN_GENE_ALM_00011_YourPleCont")}
        </div>
        <div className="flex justify-content-center">
          <button
            className="pop-up-ok-btn"
            onClick={() => {
              window.location = "/login";
            }}
          >
            OK
          </button>
        </div>
      </Dialog>
      <Dialog
        visible={networkError || props.networkError}
        style={{ width: "40vw" }}
        breakpoints={{ "1000px": "70vw", "500px": "95vw" }}
        draggable={false}
        modal
        onHide={() => setNetworkError(false)}
      >
        <img src={networkIssueImage} className="dialoge-image" alt="Loading" />
        <div className="text-center pop-msg-lable">
          {t("WBA_GEN_GENE_ALM_00010_TherErrInte")}
        </div>
        <div className="flex justify-content-center">
          <button
            className="pop-up-ok-btn"
            onClick={() => {
              setNetworkError(false);
            }}
          >
            OK
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default SessionPopUp;
