const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  rejectMessage: "",
  intoVideoDropdownOptions: {
    community: [],
    country: [],
  },
  introVideoDropdownValues: {
    community: [],
    country: [],
  },
  childIntroVideoDetails: {},
};
const VideoReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "introVideoTableData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "intoVideoFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "detailsUpdaterCIV":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "civOnClear":
      return {
        ...state,
        childIntroVideoDetails: {},
        rejectMessage: "",
      };

    default:
      return state;
  }
};
export default VideoReducer;
