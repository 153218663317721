const initialStateVal = {
  userName: "",
  password: "",
  language: "en",
  loggedUserDetails: [],
  countriesDropDown: [],
  selectedCounty: {},
};
const LoginReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "updateLoginDetails":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};
export default LoginReducer;
