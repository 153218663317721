const initialStateVal = {
  submissionOverview: [],
  gridFilterData: {},
};
const DataGridReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "updateAll":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "updateGridFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    default:
      return state;
  }
};
export default DataGridReducer;
