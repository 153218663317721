import { combineReducers } from "redux";
import photoPendingReviewReducer from "../Components/Modules/PhotoPendingReview/Reducer/PhotoPendingReviewReducer";
import ChildIlReducer from "../Components/Modules/ChildILReview/Reducer/ChildIlReducer";
import VideoReducer from "../Components/Modules/ChildIntroVideoReview/Reducer/VideoReducer";
import ReplyLetterReducer from "../Components/Modules/ChildReplyLetterReview/Reducer/ReplyLetterReducer";
import LoginReducer from "../Components/Modules/Login/Reducer/LoginReducer";
import ChildGreetingVideoReviewReducer from "../Components/Modules/ChildGreetingVideoReview/Reducer/ChildGreetingVideoReviewReducer";
import ExceptionVideoMessageReviewReducer from "../Components/Modules/ExceptionVideoMessageReview/Reducer/ExceptionVideoMessageReviewReducer";
import ChristmasCardReducer from "../Components/Modules/ChristmasCardReview/Reducer/ChristmasCardReducer";
import DataGridReducer from "../Components/Common/SfDataGrid/DataGridReducer";
import LetterCreationReducer from "../Components/Modules/LetterCreationMode/Reducer/LetterCreationReducer";
import TemplateConfigReducer from "../Components/Modules/TemplateConfig/Reducer/TemplateConfigReducer";
import DownloadLetterReducer from "../Components/Modules/DownloadLetterTemp/Reducer/DownloadLetterReducer";
import ChildPhotoUpdateReducer from "../Components/Modules/ChildPhotoUpdate/Reducer/ChildPhotoUpdateReducer";
import SponsorLetterReducer from "../Components/Modules/Sponsor Letter/Reducer/SponsorLetterReducer";
import UploadSponsorMessageReducer from "../Components/Modules/UploadSponsorMessage/Reducer/UploadSponsorMessageReducer";
import ReplyMessageReducer from "../Components/Modules/ChildReplyMessage/Reducer/ChildReplyMessageReducer";
const rootReducer = combineReducers({
  photoPendingReviewReducer,
  ChildIlReducer,
  VideoReducer,
  ReplyLetterReducer,
  LoginReducer,
  ChildGreetingVideoReviewReducer,
  ExceptionVideoMessageReviewReducer,
  ChristmasCardReducer,
  DataGridReducer,
  LetterCreationReducer,
  TemplateConfigReducer,
  DownloadLetterReducer,
  ChildPhotoUpdateReducer,
  SponsorLetterReducer,
  UploadSponsorMessageReducer,
  ReplyMessageReducer,
});
export default rootReducer;
