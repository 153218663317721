const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  dropdownOptions: {
    community: [],
    messageType: [],
  },
  dropdownValues: {
    community: {},
    messageType: "",
  },
  smDetails: {},
};
const UploadSponsorMessageReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "SponsorMsgData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "uploadsponsoeMsgFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "smOnClear":
      return {
        ...state,
        childExceptionVideoDetails: {},
      };

    default:
      return state;
  }
};
export default UploadSponsorMessageReducer;
