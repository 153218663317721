const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  rejectMessage: "",
  dropDownValue: {
    community: [],
    country: [],
  },
  selectedValue: {
    community: [],
    country: [],
  },
  childDetails: {},
};
const PhotoPendingReviewReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "storeDataFilter":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "updateKeyWisePhoto":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "detailsUpdater":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "cgpOnClear":
      return {
        ...state,
        childDetails: {},
        rejectMessage: "",
      };
    default:
      return state;
  }
};
export default PhotoPendingReviewReducer;
