import axios from "axios";
const JWTInterceptor = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 6 * 60 * 1000, //6mins
});

const requestHandler = (request) => {
  request.meta = request.meta || {};
  request.meta.requestStartedAt = new Date().getTime();
  if (window.location.pathname === "/login") {
    let token = `${localStorage.getItem("userName")}:${localStorage.getItem(
      "password"
    )}`;
    token = btoa(token);
    request.headers.Authorization = `Basic ${token}`;
  } else {
    if (request.url.includes(process.env.REACT_APP_API_FH_BASE_URL)) {
      request.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }

    if (!request.url.includes(process.env.REACT_APP_API_FH_BASE_URL)) {
      request.headers["Ocp-Apim-Subscription-Key"] =
        process.env.REACT_APP_API_KEY;
      request.headers["Source"] = "Web";
      request.headers["UserId"] = localStorage.getItem("you");
      request.headers["Access-Control-Allow-Origin"] = "*";
      request.headers["Access-Control-Allow-Methods"] = "GET";
      // request.headers["Content-Type"] = "application/json";
    }
  }

  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = "/login";
  }

  return response;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

JWTInterceptor.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
JWTInterceptor.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
export default JWTInterceptor;
